import React from "react"
import { Flex, Text, Button, Box, Spacer } from "@chakra-ui/react"
import { TextField } from "../components/textField"
import { FormSection } from "../components/formSection"
import { useCreateAdminForm } from "../hooks/useCreateAdminForm"

type Props = {}

export const AdminCreatePage: React.FC<Props> = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    isLoading,
    formState,
  } = useCreateAdminForm()

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          管理ユーザー作成
        </Text>
      </Flex>
      <Flex direction="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormSection>
            <TextField
              ref={register({ required: true })}
              required={true}
              autoComplete="name"
              name="name"
              title="ユーザー名"
            />
            <Spacer marginTop={4} />
            <TextField
              ref={register({ required: true })}
              required={true}
              autoComplete="email"
              name="email"
              title="メールアドレス"
            />
            <Spacer marginTop={4} />
            <TextField
              ref={register({ required: true })}
              required={true}
              type="password"
              name="password"
              title="パスワード"
            />
            <Spacer marginTop={4} />
            <Box>
              <Button
                disabled={!formState.isValid}
                colorScheme="blue"
                type="submit"
                isLoading={isLoading}>
                作成
              </Button>
            </Box>
          </FormSection>
        </form>
      </Flex>
    </Flex>
  )
}
