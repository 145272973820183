import { QuizCreateFormInput } from "../hooks/useCreateQuizForm"
import { FormInput } from "../hooks/useEditQuizForm"
import { SearchQuizFormInput } from "../hooks/useSearchQuizForm"
import { QuizDetailResponse } from "../model/response/quizDetailResponse"
import { QuizListResponse } from "../model/response/quizListResponse"
import { apiClient } from "./apiClient"

export type FindQuizListType = {
  id: string
  body: string
  answer: string
  answerYomi: string
  genre: string
  genreId: string
  authorId: string
}

const convertToResponseQuiz = (data: QuizListResponse): FindQuizListType[] => {
  const a = data.quizzes.map((q) => {
    const genreIndex = data.genres.findIndex((g) => g.id === q.genreId)
    return {
      ...q,
      genre: data.genres[genreIndex].name,
    }
  })

  return a
}

export const getQuizDetail = async (
  id: string,
): Promise<{
  genre?: string
  id: string
  body: string
  answer: string
  answerYomi: string
  authorId: string
}> => {
  const response = await apiClient.get<QuizDetailResponse>(
    `/admin/v1/quiz/${id}`,
  )
  return {
    ...response.data.quiz,
    genre: response.data.genre?.id,
  }
}

export const fetchQuizNumber = async (
  body: string,
  answer: string,
  genreId: string,
  authorId: string,
): Promise<number> => {
  const response = await apiClient.get<number>("/admin/v1/quiz_length", {
    params: {
      Body: body,
      Answer: answer,
      GenreId: genreId,
      AuthorId: authorId,
    },
  })

  return response.data
}

export const findQuizList = async (
  quiz: SearchQuizFormInput,
  limit = 30,
  offset = 0,
): Promise<FindQuizListType[]> => {
  const response = await apiClient.get<QuizListResponse>("/admin/v1/quiz/", {
    params: {
      Limit: limit,
      Skip: offset,
      Body: quiz.body,
      Answer: quiz.answer,
      GenreId: quiz.genre,
      AuthorId: quiz.authorId,
    },
  })

  return convertToResponseQuiz(response.data)
}

export const createQuiz = async (quiz: QuizCreateFormInput): Promise<void> => {
  await apiClient.post("admin/v1/quiz/", {
    ...quiz,
  })
}

export const createQuizzes = async (
  quizzes: QuizCreateFormInput[],
): Promise<void> => {
  const promises = quizzes.map((quiz) => createQuiz(quiz))
  await Promise.all(promises)

  return
}

export const updateQuiz = async ({
  id,
  quiz,
}: {
  id: string
  quiz: FormInput
}): Promise<void> => {
  await apiClient.put(`/admin/v1/quiz/${id}`, {
    ...quiz,
  })
}

export const deleteQuiz = async (id: string): Promise<void> => {
  await apiClient.delete(`/admin/v1/quiz/${id}`)
}

export const deleteQuizzes = async (ids: string[]): Promise<void> => {
  Promise.all(ids.map((id) => deleteQuiz(id)))
}
