import { UserListResponse } from "../model/response/userListResponse"
import { apiClient } from "./apiClient"

function convertToGetUserList(data: UserListResponse) {
  return data.map((d) => {
    return {
      id: d.id,
      name: d.name,
      icon: d.icon,
      bio: d.bio,
      displayId: d.displayId,
      genreId: d.genreId,
      ...d.sys,
    }
  })
}

export const getUserList = async ({
  limit,
}: {
  limit: number
}): Promise<any> => {
  const response = await apiClient.get<UserListResponse>("/admin/v1/user/", {
    params: {
      limit: limit,
    },
  })
  return convertToGetUserList(response.data)
}

export const updateUser = async ({
  id,
  user,
}: {
  id: string
  user: {
    id: string
    name: string
    profile: string
    rank: number
    numOfMatch: number
    rate: number
  }
}) => {
  await apiClient.put(`/admin/v1/user/${id}`, {
    user: user,
  })
}
