import { apiClient } from "./apiClient"

export const login = async (
  email: string,
  password: string,
): Promise<string> => {
  const response = await apiClient.post<string>("/admin/v1/login", {
    email,
    password,
  })
  return response.data
}
