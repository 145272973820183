import { useState } from "react"
import { UseFormMethods, useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/react"
import { createAdmin } from "../repositories/admin"
import { useHistory } from "react-router-dom"

export type FormInput = {
  name: string
  password: string
  email: string
}

export const useCreateAdminForm = (): UseFormMethods<FormInput> & {
  onSubmit: (data: FormInput) => Promise<void>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const toast = useToast()

  const onSubmit = async (data: FormInput) => {
    setIsLoading(true)
    try {
      await createAdmin(data)
      history.push("/admins")
    } catch (e) {
      toast({
        position: "top",
        title: "管理ユーザー作成に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    ...useForm<FormInput>({ mode: "onChange" }),
    onSubmit,
    isLoading,
  }
}
