import React from "react"
import {
  Flex,
  Text,
  Button,
  Box,
  CircularProgress,
  Spacer,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { useEditAdminForm } from "../hooks/useEditAdminForm"
import { FormSection } from "../components/formSection"
import { TextField } from "../components/textField"

type Props = {}

export const AdminEditPage: React.FC<Props> = () => {
  const { id: adminId } = useParams<{ id: string }>()
  const {
    register,
    handleSubmit,
    onSubmit,
    onDelete,
    errors,
    isLoading,
    formState,
    fetchSucceeded,
    isLastAdmin,
  } = useEditAdminForm(adminId)

  const { isSubmitting } = formState

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          管理ユーザー詳細
        </Text>
        <Button
          type="button"
          colorScheme="red"
          isLoading={isLoading}
          disabled={isLastAdmin}
          onClick={() => {
            if (window.confirm("ユーザーを削除")) {
              onDelete(adminId)
            }
          }}>
          管理ユーザーを削除する
        </Button>
      </Flex>
      <Flex direction="column" overflowX="scroll">
        <Box backgroundColor={["gray.100", "background"]} padding={4}>
          {fetchSucceeded ? (
            <Flex direction="column">
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormSection>
                  <FormControl isInvalid={errors.name != null}>
                    <TextField
                      ref={register({ required: true })}
                      required={true}
                      name="name"
                      title="ユーザー名"
                    />
                    <FormErrorMessage>
                      {errors.name && "ユーザー名が入力されていません"}
                    </FormErrorMessage>
                  </FormControl>
                  <Spacer marginTop={4} />
                  <FormControl isInvalid={errors.email != null}>
                    <TextField
                      ref={register({ required: true })}
                      required={true}
                      name="email"
                      title="メールアドレス"
                      // defaultValue={email}
                    />
                    <FormErrorMessage>
                      {errors.email && "メールアドレスが入力されていません"}
                    </FormErrorMessage>
                  </FormControl>
                  <Spacer marginTop={4} />
                  <Box>
                    <Button
                      disabled={isSubmitting}
                      colorScheme="blue"
                      type="submit"
                      isLoading={isLoading}>
                      編集
                    </Button>
                  </Box>
                </FormSection>
              </form>
            </Flex>
          ) : (
            <Flex alignItems="center" justifyContent="center" padding={6}>
              <CircularProgress color="blue.500" isIndeterminate={true} />
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
