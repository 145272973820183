import { useCallback, useState } from "react"
import { useDropzone, DropzoneState } from "react-dropzone"
import { uploadImage } from "../repositories/genre"
import { useToast } from "@chakra-ui/react"

type UseMediaUploadParam = {
  config?: {
    accept?: string[]
    maxSize?: number
  }
  onUpload: (url: string) => void
}

export const useImagePicker = ({
  config,
  onUpload,
}: UseMediaUploadParam): DropzoneState & {
  isUploading: boolean
} => {
  const toast = useToast
  const [isUploading, setIsUploading] = useState(false)

  const onDrop = useCallback(
    async (acceptedFile: File[]) => {
      setIsUploading(true)
      try {
        const res = await uploadImage(acceptedFile[0])
        onUpload(res.imageUrl)
      } catch {
        toast({
          position: "top",
          title: "アップロードに失敗しました",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      } finally {
        setIsUploading(false)
      }
    },
    [toast, onUpload],
  )

  const defaultConfig = {
    accept: ["image/jpeg", "image/png"],
    maxSize: 15000000, // 15MB
  }
  const { accept, maxSize } = {
    ...defaultConfig,
    ...config,
  }
  const useDropzoneParams = useDropzone({
    onDrop,
    maxSize,
    accept,
  })

  return {
    ...useDropzoneParams,
    isUploading,
  }
}
