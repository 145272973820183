import React from "react"
import {
  Button,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react"
import { QuizCreateFormInput } from "../../hooks/useCreateQuizForm"

export type Props = {
  data: QuizCreateFormInput[]
  isOpen: boolean
  onClose: () => void
  handleImport: () => void
}

export const CsvPreviewModal: React.FC<Props> = ({
  data,
  isOpen,
  onClose,
  handleImport,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleImport}>
        <ModalContent maxWidth="auto" marginX={8}>
          <ModalHeader>プレビュー</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="auto">
            <Flex
              flexDirection="column"
              marginTop={2}
              maxHeight="500px"
              overflowY="scroll">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>
                      <Text fontSize="md" whiteSpace="nowrap">
                        問題文
                      </Text>
                    </Th>
                    <Th>
                      <Text fontSize="md" whiteSpace="nowrap">
                        回答
                      </Text>
                    </Th>
                    <Th>
                      <Text fontSize="md" whiteSpace="nowrap">
                        回答（読み）
                      </Text>
                    </Th>
                    <Th>
                      <Text fontSize="md" whiteSpace="nowrap">
                        作成者
                      </Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody width="100%">
                  {data.map((row, i) => (
                    <Tr key={i}>
                      <Td width="30%">
                        <Text fontSize="md" wordBreak="break-all">
                          {row.body}
                        </Text>
                      </Td>
                      <Td width="25%">
                        <Text fontSize="md" wordBreak="break-all">
                          {row.answer}
                        </Text>
                      </Td>
                      <Td width="25%">
                        <Text fontSize="md" wordBreak="break-all">
                          {row.answeryomi}
                        </Text>
                      </Td>
                      <Td width="20%">
                        <Text fontSize="md" wordBreak="break-all">
                          {row.authorid}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>
          </ModalBody>

          <ModalFooter borderTopColor="line" borderTopWidth={1}>
            <Button type="button" color="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button type="submit" colorScheme="blue">
              インポート
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
