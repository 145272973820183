import React, { useCallback, useEffect } from "react"
import {
  Flex,
  Text,
  Button,
  Box,
  useToast,
  CircularProgress,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react"
import { useHistory, RouteComponentProps } from "react-router"
import useSWR from "swr"
import { TextField } from "../components/textField"
import { DataTable } from "../components/dataTable"
import { Column, Row } from "react-table"
import { FormSection } from "../components/formSection"
import { SelectField } from "../components/selectField"
import { useSearchQuizForm } from "../hooks/useSearchQuizForm"
import { useImportQuizCSV } from "../hooks/useImportQuizCSV"
import { getGenreList } from "../repositories/genre"
import { TextWithDots } from "../components/dots"
import { FileField } from "../components/fileField"
import { isNumber } from "../utils/regexp"
import { useQuery } from "../hooks/useQuery"
import { FindQuizListType, fetchQuizNumber } from "../repositories/quiz"
import { CsvPreviewModal } from "../components/CsvPreviewModal"

type Props = {
  page: string
}

type QuizListPageProps = RouteComponentProps<Props>

export const QuizListPage: React.FC<QuizListPageProps> = ({ match }) => {
  const query = useQuery()
  const [body, answer, genreId, authorId] = [
    query.get("body"),
    query.get("answer"),
    query.get("genreId"),
    query.get("authorId"),
  ]
  const { page } = match.params
  const history = useHistory()
  const toast = useToast()
  const [selectedIds, setSelectedIds] = React.useState<string[]>([])

  // クイズ総数を取得
  const { data: quizNumber } = useSWR(
    ["/fetchQuizNumber", body, answer, genreId, authorId],
    () =>
      fetchQuizNumber(body ?? "", answer ?? "", genreId ?? "", authorId ?? ""),
  )

  const {
    register,
    handleSubmit,
    onSubmit,
    isLoading,
    res,
    onDeleteQuizzes,
  } = useSearchQuizForm(
    isNumber(page) ? Number(page) : 1,
    body ?? "",
    answer ?? "",
    genreId ?? "",
    authorId ?? "",
  )

  const {
    isProcessing,
    csvPreviewData,
    isOpen,
    onUploadCsvFile,
    handleImport,
    onClose,
  } = useImportQuizCSV()
  const { data: genre, error: genreError } = useSWR(
    "/getGenreList",
    getGenreList,
  )

  const columns: Column<FindQuizListType>[] = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "問題文",
        accessor: "body",
        Cell: ({ cell: { value } }) => (
          <TextWithDots maxWidth="200px">{value}</TextWithDots>
        ),
      },
      {
        Header: "ジャンル",
        accessor: "genre",
      },
      {
        Header: "作成者",
        accessor: "authorId",
      },
      {
        Header: "詳細",
        Cell: ({ cell: { row } }: any) => (
          // TODO: anyを消す
          <Button
            type="button"
            colorScheme="blue"
            onClick={() => handleRowClick(row)}>
            詳細
          </Button>
        ),
      },
    ],
    [],
  )

  useEffect(() => {
    if (genreError) {
      toast({
        position: "top",
        title: "データの取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }, [genreError, toast])

  const handleClick = useCallback(() => {
    history.push("/quizzes/new")
  }, [history])

  const handleRowClick = useCallback(
    (row: Row<FindQuizListType>) => {
      history.push(`/quizzes/${row.original.id}/edit`)
    },
    [history],
  )

  const prevPage = React.useCallback(() => {
    if (page != null) {
      if (isNumber(page) && Number(page) > 1) {
        history.push(
          `/quizzes/${
            Number(page) - 1
          }?body=${body}&answer=${answer}&genreId=${genreId}&authorId=${authorId}`,
        )
      } else {
        history.push(
          `/quizzes/1?body=${body}&answer=${answer}&genreId=${genreId}&authorId=${authorId}`,
        )
      }
    } else {
      history.push(
        `/quizzes/1?body=${body}&answer=${answer}&genreId=${genreId}&authorId=${authorId}`,
      )
    }
  }, [body, answer, genreId, authorId, page, history])

  const nextPage = React.useCallback(() => {
    if (page != null) {
      if (isNumber(page)) {
        history.push(
          `/quizzes/${
            Number(page) + 1
          }?body=${body}&answer=${answer}&genreId=${genreId}&authorId=${authorId}`,
        )
      } else {
        history.push(
          `/quizzes/1?body=${body}&answer=${answer}&genreId=${genreId}&authorId=${authorId}`,
        )
      }
    } else {
      history.push(
        `/quizzes/2?body=${body}&answer=${answer}&genreId=${genreId}&authorId=${authorId}`,
      )
    }
  }, [body, answer, genreId, authorId, page, history])

  const onSelectedRows = useCallback(
    (rows: Row<FindQuizListType>[]) => {
      setSelectedIds(rows.map((row) => row.original.id))
    },
    [setSelectedIds],
  )

  return (
    <>
      <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
        <Flex
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginTop={6}
          marginBottom={4}
          paddingX={4}>
          <Text color="primaryText" fontSize="lg" fontWeight="bold">
            クイズ一覧
          </Text>
          <Flex>
            <Button marginRight={2} colorScheme="blue" onClick={handleClick}>
              新規作成
            </Button>
            <FileField
              label={"CSVインポート"}
              isLoading={isProcessing}
              isDisabled={false}
              accept={".csv"}
              onChange={(file: File) => onUploadCsvFile(file)}
            />
          </Flex>
        </Flex>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormSection>
            <Flex direction="column">
              <SimpleGrid minChildWidth={200} spacingX={20} spacingY={8}>
                <TextField ref={register} title="問題文" name="body" />
                <TextField ref={register} title="解答" name="answer" />
              </SimpleGrid>
              <Spacer marginTop={3} />
              <SimpleGrid minChildWidth={120} spacingX={20} spacingY={8}>
                <SelectField
                  ref={register}
                  title="ジャンル"
                  name="genre"
                  items={genre ?? []}
                />
                <TextField ref={register} title="作成者" name="authorId" />
              </SimpleGrid>
              <Spacer marginTop={10} />
              <Flex justifyContent="center">
                <Button
                  colorScheme="blue"
                  w="30%"
                  type="submit"
                  isLoading={isLoading}>
                  検索
                </Button>
              </Flex>
            </Flex>
          </FormSection>
        </form>

        <Flex direction="column" overflowX="scroll">
          <Box backgroundColor={["gray.100", "background"]} p={4} mt={10}>
            {isLoading || quizNumber == null ? (
              <Flex alignItems="center" justifyContent="center" padding={6}>
                <CircularProgress color="blue.500" isIndeterminate={true} />
              </Flex>
            ) : (
              <Box>
                <Text color="black" fontWeight="bold" mb={2}>
                  {quizNumber}件のクイズうち{30 * (Number(page) - 1) + 1}〜
                  {Math.min(30 * Number(page) + 1, quizNumber)}件目を表示
                </Text>
                {selectedIds.length > 0 && (
                  <Button
                    type="button"
                    colorScheme="red"
                    isLoading={isLoading}
                    onClick={() => {
                      if (window.confirm("選択されたデータを削除します")) {
                        onDeleteQuizzes(selectedIds)
                      }
                    }}>
                    選択されたデータを削除
                  </Button>
                )}
                <DataTable
                  selectableRows
                  sortable
                  data={res ?? []}
                  columns={columns}
                  onSelectedRows={onSelectedRows}
                />
              </Box>
            )}
          </Box>
          <Flex justifyContent="flex-end">
            <Button marginRight={2} onClick={prevPage}>
              戻る
            </Button>
            <Button onClick={nextPage}>次へ</Button>
          </Flex>
        </Flex>
      </Flex>

      <CsvPreviewModal
        data={csvPreviewData}
        isOpen={isOpen}
        handleImport={handleImport}
        onClose={onClose}
      />
    </>
  )
}
