import React from "react"
import {
  Input,
  Flex,
  InputProps,
  Textarea,
  TextareaProps,
  FormLabel,
  Box,
} from "@chakra-ui/react"
import { Badge } from "../badge"

export type Props = InputProps &
  TextareaProps & {
    title: string
    required?: boolean
    multiline?: boolean
    disabled?: boolean
  }

export const TextField = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  Props
>(({ required, multiline, title, ...rest }, ref) => {
  return (
    <Box>
      <Flex display="flex" alignItems="center">
        <FormLabel fontWeight="bold">{title}</FormLabel>
        {required && <Badge marginBottom={2}>必須</Badge>}
      </Flex>
      {multiline ? (
        <Textarea {...rest} ref={ref as React.Ref<HTMLTextAreaElement>} />
      ) : (
        <Input {...rest} ref={ref as React.Ref<HTMLInputElement>} />
      )}
    </Box>
  )
})
