import React, { useCallback, useEffect } from "react"
import { Flex, Text, Box, useToast, CircularProgress } from "@chakra-ui/react"
import { Column, Row } from "react-table"
import { useHistory } from "react-router-dom"
import { DataTable } from "../components/dataTable"
import useSWR from "swr"
import { User } from "../model/entity/user"
import { getUserList } from "../repositories/user"
import { Icon } from "../components/icon"

type Props = {}

export const usersMockData: User[] = [
  {
    id: "1",
    name: "sho",
    icon:
      "http://flat-icon-design.com/f/f_object_157/s256_f_object_157_0bg.png",
    bio: "hello",
    // created: 0,
    rank: 3,
    // lastLogin: 0,
    // numOfMatch: 32,
    // rate: 2,
    displayId: "",
    genreId: "",
    consecutiveWinsCount: 0,
    gamesCount: 1,
    score: 100,
    winsCount: 10,
  },
  {
    id: "2",
    name: "mao",
    icon:
      "http://flat-icon-design.com/f/f_object_149/s256_f_object_149_0bg.png",
    bio: "world",
    // created: 0,
    rank: 100,
    // lastLogin: 0,
    // numOfMatch: 1,
    // rate: 20,

    displayId: "",
    genreId: "",
    consecutiveWinsCount: 0,
    gamesCount: 1,
    score: 100,
    winsCount: 10,
  },
]

export const UserListPage: React.FC<Props> = () => {
  const history = useHistory()
  const toast = useToast()
  const { data, error } = useSWR("/getUserList", getUserList)

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        Header: "ユーザーID",
        accessor: "id",
      },
      {
        Header: "ユーザー名",
        accessor: "name",
      },
      {
        Header: "ユーザーアイコン",
        accessor: "icon",
        Cell: ({ cell: { value } }) => <Icon src={value} />,
      },
      {
        Header: "プロフィール文章",
        accessor: "bio",
      },
      {
        Header: "ランク",
        accessor: "rank",
      },
      {
        Header: "マッチ数",
        accessor: "gamesCount",
      },
      // {
      //   Header: "最終ログイン日",
      //   accessor: "lastLogin",
      // },
      // {
      //   Header: "登録日時",
      //   accessor: "created",
      // },
      {
        Header: "スコア",
        accessor: "score",
      },
    ],
    [],
  )

  const onHandleRowClick = useCallback(
    (row: Row<User>) => {
      history.push(`/user/${row.original.id}/edit`)
    },
    [history],
  )

  useEffect(() => {
    if (error) {
      toast({
        position: "top",
        title: "データの取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }, [error, toast])

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          ユーザー一覧
        </Text>
      </Flex>
      <Flex direction="column" overflowX="scroll">
        <Box backgroundColor={["gray.100", "background"]} padding={4}>
          {data == null ? (
            <Flex alignItems="center" justifyContent="center" padding={6}>
              <CircularProgress color="blue.500" isIndeterminate={true} />
            </Flex>
          ) : (
            <Flex>
              <DataTable
                data={data}
                columns={columns}
                searchable={true}
                // onRowClick={onHandleRowClick}
              />
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
