import { apiClient } from "./apiClient"
import { AdminListResponse } from "../model/response/adminListResponse"
import { AdminDetailResponse } from "../model/response/adminDetailResponse"

export const getAdminList = async (): Promise<AdminListResponse> => {
  // const response = await apiClient.get<AdminListResponse>("/admin/v1/admin?", {
  //   params: {
  //     limit: limit,
  //     offset: offset,
  //   },
  // })
  const response = await apiClient.get<AdminListResponse>("/admin/v1/admin/")
  return response.data
}

export const getAdminDetail = async (
  id: string,
): Promise<AdminDetailResponse> => {
  const response = await apiClient.get<AdminDetailResponse>(
    `/admin/v1/admin/${id}`,
  )
  return response.data
}

export const createAdmin = async (admin: {
  name: string
  email: string
  password: string
}): Promise<void> => {
  await apiClient.post("/admin/v1/admin/", {
    ...admin,
  })
}

export const deleteAdmin = async (id: string) => {
  await apiClient.delete(`/admin/v1/admin/${id}`)
}

export const updateAdmin = async ({
  id,
  admin,
}: {
  id: string
  admin: {
    name: string
    email: string
  }
}) => {
  await apiClient.put(`/admin/v1/admin/${id}`, {
    name: admin.name,
    email: admin.email,
  })
}
