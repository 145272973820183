import * as React from "react"
import { ChakraProvider, CSSReset } from "@chakra-ui/react"
import theme from "./chakra"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { AdminListPage } from "./pages/adminList"
import { AdminCreatePage } from "./pages/adminCreate"
import { AdminEditPage } from "./pages/adminEdit"
import { LoginPage } from "./pages/login"
import { GenreListPage } from "./pages/genreList"
import { AppContainer } from "./components/appContainer"
import { ScrollToTop } from "./components/scrollToTop"
import { UserListPage } from "./pages/userList"
import { UserEditPage } from "./pages/userEdit"
import { QuizListPage } from "./pages/quizList"
import { QuizCreatePage } from "./pages/quizCreate"
import { QuizEditPage } from "./pages/quizEdit"
import { RankingPage } from "./pages/ranking"
import { PrivateRoute } from "./privateRoute"

export const App: React.FC = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <ScrollToTop />
      <CSSReset />
      {/* <Provider store={configureStore(history)}> */}
      <AppContainer>
        {/* TODO: プライベート */}
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route path="/ranking">
          <RankingPage />
        </Route>
        <Switch>
          <Redirect exact from="/" to="/quizzes/1" />
          <PrivateRoute exact path="/admins" component={AdminListPage} />
          <PrivateRoute path="/admins/new" component={AdminCreatePage} />
          <PrivateRoute path="/admins/:id" component={AdminEditPage} />
          <PrivateRoute path="/users" component={UserListPage} />
          <PrivateRoute path="/user/:id/edit" component={UserEditPage} />
          <PrivateRoute exact path="/quizzes/new" component={QuizCreatePage} />
          <PrivateRoute exact path="/quizzes/:page" component={QuizListPage} />
          <Redirect exact from="/quizzes" to="/quizzes/1" />
          <PrivateRoute
            exact
            path="/quizzes/:id/edit"
            component={QuizEditPage}
          />
          <PrivateRoute exact path="/genres" component={GenreListPage} />
        </Switch>
      </AppContainer>
      {/* </Provider> */}
      <ToastContainer />
    </Router>
  </ChakraProvider>
)
