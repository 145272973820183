import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { UseFormMethods, useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/react"
import {
  findQuizList,
  FindQuizListType,
  deleteQuizzes,
} from "../repositories/quiz"

export type SearchQuizFormInput = {
  body: string
  answer: string
  genre?: string
  authorId: string
}

const LIMIT = 30

export const useSearchQuizForm = (
  page: number,
  body: string,
  answer: string,
  genreId: string,
  authorId: string,
): UseFormMethods<SearchQuizFormInput> & {
  onSubmit: (data: SearchQuizFormInput) => Promise<void>
  onDeleteQuizzes: (ids: string[]) => Promise<void>
  isLoading: boolean
  res: FindQuizListType[]
} => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const [res, setRes] = useState<FindQuizListType[]>([])
  const { reset, ...rest } = useForm<SearchQuizFormInput>()

  const getQuizList = useCallback(async () => {
    reset({
      body,
      answer,
      genre: genreId,
      authorId,
    })

    setIsLoading(true)
    try {
      const response = await findQuizList(
        { body, answer, genre: genreId, authorId },
        LIMIT,
        (page - 1) * LIMIT,
      )
      setRes(response)
      history.push(
        `/quizzes/${page}?body=${body}&answer=${answer}&genreId=${genreId}&authorId=${authorId}`,
      )
    } catch (e) {
      toast({
        position: "top",
        title: "クイズの取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }, [body, answer, genreId, authorId, page, history, toast])

  const onSubmit = useCallback(
    async (data: SearchQuizFormInput) => {
      setIsLoading(true)
      try {
        const response = await findQuizList(data, LIMIT, 0)
        setRes(response)
        history.push(
          `/quizzes/1?body=${data.body}&answer=${data.answer}&genreId=${data.genre}&authorId=${data.authorId}`,
        )
      } catch (e) {
        toast({
          position: "top",
          title: "クイズの取得に失敗しました",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [history, toast],
  )

  const onDeleteQuizzes = useCallback(
    async (ids: string[]) => {
      Promise.all([deleteQuizzes(ids), getQuizList()])
    },
    [getQuizList],
  )

  useEffect(() => {
    getQuizList()
  }, [page, getQuizList])

  return {
    ...rest,
    reset,
    onSubmit,
    onDeleteQuizzes,
    isLoading,
    res,
  }
}
