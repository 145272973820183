import React, { useCallback, useEffect, useState } from "react"
import {
  Flex,
  Text,
  Box,
  NumberInputField,
  NumberInput,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  Spacer,
  SimpleGrid,
  FormLabel,
  Image,
  CircularProgress,
  useToast,
  useDisclosure,
} from "@chakra-ui/react"

import { Column, Row } from "react-table"
import useSWR from "swr"
import { useHistory, useParams } from "react-router-dom"
import { DataTable } from "../components/dataTable"
import { User } from "../model/entity/user"
import { getUserList } from "../repositories/user"
import { usersMockData } from "./userList"
import { FormSection } from "../components/formSection"
import { useCreateAdminForm } from "../hooks/useCreateAdminForm"
import { TextField } from "../components/textField"
import { useEditUserForm } from "../hooks/useEditUserForm"
import { Icon } from "../components/icon"
import { MyModal } from "../components/modal"
import { NumberField } from "../components/numberField"

type Props = {}

export const UserEditPage: React.FC<Props> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id: userId } = useParams<{ id: string }>()
  const toast = useToast()
  const [modalIsOpened, setModalIsOpened] = useState(false)

  // TODO
  const error = null
  const data = usersMockData
  const user = data.find((user) => user.id === userId)

  const {
    register,
    handleSubmit,
    onSubmit,
    isLoading,
    formState,
  } = useEditUserForm(user)

  const handleClick = useCallback(() => {
    console.log("delete")
  }, [])

  useEffect(() => {
    if (error) {
      toast({
        position: "top",
        title: "データの取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }, [error, toast])

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          ユーザー詳細
        </Text>
      </Flex>
      <Box backgroundColor={["gray.100", "background"]} padding={4}>
        {user == null ? (
          <Flex alignItems="center" justifyContent="center" padding={6}>
            <CircularProgress color="blue.500" isIndeterminate={true} />
          </Flex>
        ) : (
          <Flex direction="column">
            <form>
              <FormSection>
                <TextField
                  ref={register({ required: true })}
                  autoComplete="id"
                  name="id"
                  title="ユーザーID"
                />
                <Spacer marginTop={8} />
                <SimpleGrid minChildWidth={140} spacing={8}>
                  <Box>
                    <Flex alignItems="center">
                      <Text fontWeight="bold">ユーザーアイコン</Text>
                    </Flex>
                    <Icon src={user.icon} h={100} w={100} />
                  </Box>
                  <Box>
                    <TextField
                      ref={register({ required: true })}
                      autoComplete="name"
                      name="name"
                      title="ユーザー名"
                    />
                  </Box>
                </SimpleGrid>
                <Spacer marginTop={4} />
                <TextField
                  ref={register({ required: true })}
                  multiline
                  name="profile"
                  title="プロフィール文章"
                  h={200}
                />
                <Spacer marginTop={4} />
                <SimpleGrid minChildWidth={200} spacingX={20} spacingY={8}>
                  <TextField
                    ref={register}
                    disabled
                    name="created"
                    title="登録日時"
                  />
                  <NumberField
                    ref={register}
                    title="ランク"
                    initialValue={user.rank}
                    name="rank"
                  />
                  {/* <TextField
                    disabled
                    name="lastLogin"
                    title="最終ログイン日"
                    value={user.lastLogin}
                  /> */}
                  <NumberField
                    ref={register}
                    name="gamesCount"
                    title="マッチ(試合)数"
                    initialValue={user.gamesCount}
                  />
                  <NumberField
                    ref={register}
                    name="score"
                    title="スコア"
                    initialValue={user.score}
                  />
                </SimpleGrid>
                <Spacer marginTop={7} />
                <Flex justifyContent="flex-end">
                  <Button
                    disabled={!formState.isValid}
                    colorScheme="blue"
                    isLoading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                    更新
                  </Button>
                  <Button
                    colorScheme="red"
                    isLoading={isLoading}
                    marginLeft={9}
                    onClick={onOpen}>
                    強制退会
                  </Button>
                </Flex>
              </FormSection>
            </form>
            <MyModal
              button={{ name: "強制退会", onClick: handleClick }}
              isOpen={isOpen}
              onClose={onClose}>
              {user.name}を強制退会しますか
            </MyModal>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}
