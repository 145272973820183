import { Flex, Image, FlexProps } from "@chakra-ui/react"
import React from "react"

type Props = FlexProps & {
  src: string
}

export const Icon: React.FC<Props> = ({ src, ...rest }) => {
  return (
    <Flex
      width="60px"
      height="60px"
      backgroundColor="gray.100"
      alignItems="center"
      justifyContent="center"
      rounded="md"
      overflow="hidden"
      {...rest}
      margin={2}>
      {src !== "" && (
        <Image src={src} width="60px" height="60px" h={rest.h} w={rest.w} />
      )}
    </Flex>
  )
}
