import { useEffect, useState } from "react"
import { UseFormMethods, useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/react"
// import { useHistory } from "react-router-dom"
import { getQuizDetail, updateQuiz, deleteQuiz } from "../repositories/quiz"

export type FormInput = {
  body: string
  answer: string
  answeryomi: string
  genreid: string
  authorid: string
}

export const useEditQuizForm = (
  quizId: string,
): UseFormMethods<FormInput> & {
  onSubmit: (data: FormInput) => Promise<void>
  onDelete: (id: string) => Promise<void>
  isLoading: boolean
  fetchSucceeded: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)
  const [fetchSucceeded, setFetchSucceeded] = useState(false)
  const toast = useToast()
  const { reset, ...rest } = useForm<FormInput>()

  const fetchQuiz = async () => {
    try {
      const quiz = await getQuizDetail(quizId)
      reset({
        body: quiz.body,
        answer: quiz.answer,
        answeryomi: quiz.answerYomi,
        genreid: quiz.genre,
        authorid: quiz.authorId,
      })
      setFetchSucceeded(true)
    } catch {
      toast({
        position: "top",
        title: "データの取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (quizId) {
      fetchQuiz()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId])

  const onSubmit = async (data: FormInput) => {
    setIsLoading(true)
    try {
      await updateQuiz({ id: quizId, quiz: data })
      window.history.back()
    } catch (e) {
      toast({
        position: "top",
        title: "クイズの編集に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onDelete = async (id: string) => {
    setIsLoading(true)
    try {
      await deleteQuiz(id)
      toast({
        position: "top",
        title: "削除が完了しました",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
      window.history.back()
    } catch {
      toast({
        position: "top",
        title: "削除に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    ...rest,
    reset,
    isLoading,
    fetchSucceeded,
    onSubmit,
    onDelete,
  }
}
