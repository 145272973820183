import React, { useEffect, useRef } from "react"

export type Props = React.ComponentPropsWithRef<"input"> & {
  indeterminate?: boolean
}

export const IndeterminateCheckbox: React.FC<Props> = ({
  indeterminate,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (ref.current && indeterminate != null) {
      ref.current.indeterminate = indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      ref={ref}
      type="checkbox"
      {...rest}
      style={{ width: "20px", height: "20px" }}
    />
  )
}
