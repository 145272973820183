import React from "react"
import { Text, TextProps, Flex } from "@chakra-ui/react"

export type Props = TextProps & {}

export const Badge: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Flex display="inline-flex">
      <Text
        paddingX={1}
        paddingY={0.5}
        borderRadius={2}
        backgroundColor="gray.200"
        color="subText"
        fontSize="xs"
        fontWeight="bold"
        {...rest}>
        {children}
      </Text>
    </Flex>
  )
}
