import { Flex, FlexProps } from "@chakra-ui/react"
import React from "react"

type Props = FlexProps & {
  active: boolean
  title: string
}

export const Tab: React.FC<Props> = ({ active, title, ...rest }) => {
  return (
    <Flex
      {...rest}
      w="50%"
      h={50}
      justifyContent="center"
      alignItems="center"
      bgColor={active ? "gray.200" : ""}
      borderRadius="20px 20px 0 0"
      cursor="pointer">
      {title}
    </Flex>
  )
}
