import React, { useEffect } from "react"
import {
  Flex,
  Text,
  Button,
  Box,
  CircularProgress,
  Spacer,
  SimpleGrid,
  IconButton,
  FormLabel,
} from "@chakra-ui/react"
import { Column, Row } from "react-table"
import { DataTable } from "../components/dataTable"
import { Genre } from "../model/entity/genre"
import { FormSection } from "../components/formSection"
import { TextField } from "../components/textField"
import { useCreateGenreForm } from "../hooks/useCreateGenreForm"
import { Icon } from "../components/icon"
import { CloseIcon } from "@chakra-ui/icons"
import { Badge } from "../components/badge"
import { SingleImagePicker } from "../components/singleImagePicker"
import { Controller } from "react-hook-form"
import { CompactPicker } from "react-color"

type Props = {}

export const GenreListPage: React.FC<Props> = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    onDelete,
    control,
    isLoading,
    formState,
    data,
  } = useCreateGenreForm()

  const columns: Column<Genre>[] = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "ジャンル",
        accessor: "name",
      },
      {
        Header: "ジャンルカラー",
        accessor: "keyColor",
        Cell: ({ cell: { value } }) => (
          <Flex flexDirection="column" alignItems="center">
            <Text>{value}</Text>
            <Flex width="20px" height="20px" backgroundColor={value} />
          </Flex>
        ),
      },
      {
        Header: "アイコン画像",
        accessor: "iconUrl",
        Cell: ({ cell: { value } }) => <Icon src={value} />,
      },
      {
        Header: "イメージ画像",
        accessor: "imageUrl",
        Cell: ({ cell: { value } }) => <Icon src={value} />,
      },
      {
        Header: "アクション",
        Cell: ({ row }: { row: Row<Genre> }) => (
          <Box textAlign="center">
            <IconButton
              icon={<CloseIcon color="red.500" />}
              aria-label="Delete data"
              onClick={() => onDelete(row.original.id)}
            />
          </Box>
        ),
      },
    ],
    [onDelete],
  )

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          ジャンル管理
        </Text>
      </Flex>

      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormSection>
            <SimpleGrid minChildWidth={200} spacing={7} placeItems="center">
              <Flex direction="column" w="100%">
                <TextField
                  ref={register({ required: true })}
                  required={true}
                  name="name"
                  title="ジャンル名"
                />
                <Spacer marginTop={3} />
                <Flex display="flex" flexDir="row">
                  <FormLabel fontWeight="bold">ジャンルカラー</FormLabel>
                  <Badge marginBottom={2}>必須</Badge>
                </Flex>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="keyColor"
                  render={({ onChange, value }) => (
                    <CompactPicker
                      color={value}
                      onChange={(color) => onChange(color.hex)}
                    />
                  )}
                />
                <Spacer marginTop={6} />
              </Flex>
              <Flex display="flex" flexDir="column">
                <Flex display="flex" flexDir="row">
                  <FormLabel fontWeight="bold">アイコン画像</FormLabel>
                  <Badge marginBottom={2}>必須</Badge>
                </Flex>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="iconUrl"
                  render={({ onChange, value }) => (
                    <SingleImagePicker
                      width="140px"
                      height="140px"
                      aspect={1 / 1}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Flex>
              <Flex display="flex" flexDir="column">
                <Flex display="flex" flexDir="row">
                  <FormLabel fontWeight="bold">イメージ画像</FormLabel>
                  <Badge marginBottom={2}>必須</Badge>
                </Flex>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="imageUrl"
                  render={({ onChange, value }) => (
                    <SingleImagePicker
                      width="140px"
                      height="140px"
                      aspect={1 / 1}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Flex>
            </SimpleGrid>
            <Box>
              <Button
                disabled={!formState.isValid}
                colorScheme="blue"
                type="submit"
                isLoading={isLoading}>
                ジャンル追加
              </Button>
            </Box>
          </FormSection>
        </form>
      </Box>

      <Flex direction="column" overflowX="scroll">
        <Box backgroundColor={["gray.100", "background"]} padding={4}>
          {data == null ? (
            <Flex alignItems="center" justifyContent="center" padding={6}>
              <CircularProgress color="blue.500" isIndeterminate={true} />
            </Flex>
          ) : (
            <DataTable data={data} columns={columns} searchable={true} />
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
