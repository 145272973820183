import React from "react"
import { Flex, Text, Spacer } from "@chakra-ui/react"

export type Props = {
  title?: string
  description?: string
}

export const FormSection: React.FC<Props> = ({
  title,
  description,
  children,
}) => {
  return (
    <Flex
      display="flex"
      flexDirection="column"
      padding={4}
      background="background">
      {title && (
        <Text fontWeight="bold" fontSize="lg">
          {title}
        </Text>
      )}
      {description && (
        <Text marginTop={2} color="subText" fontSize="sm">
          {description}
        </Text>
      )}
      {title && <Spacer marginTop={4} />}
      <Flex display="flex" flexDirection="column">
        {children}
      </Flex>
    </Flex>
  )
}
