export function isNumber(src: unknown): boolean {
  switch (typeof src) {
    case "number": {
      return true
    }
    case "string": {
      return /^[0-9]+$/.test(src)
    }
  }

  return false
}
