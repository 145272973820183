import { Select } from "@chakra-ui/react"
import React, { FC, useMemo } from "react"
import useSWR from "swr"
import { getGenreList } from "../../repositories/genre"

export type Props = {
  selectedGenreId: string
  placeholder?: string
  onChange: (genreId: string) => void
}

export const SelectGenre: FC<Props> = ({
  selectedGenreId,
  placeholder = "ジャンルを選択してください",
  onChange,
}) => {
  const { data } = useSWR("/fetchGenres", getGenreList)

  return (
    <Select
      backgroundColor="background"
      placeholder={placeholder}
      value={selectedGenreId}
      onChange={(e) => onChange(e.target.value)}>
      {data?.map((d) => (
        <option key={d.id} value={d.id}>
          {d.name}
        </option>
      ))}
    </Select>
  )
}
