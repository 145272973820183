import { GenreCreateFormInput } from "../hooks/useCreateGenreForm"
import { GenreListResponse } from "../model/response/genreListResponse"
import { ImageUploadResponse } from "../model/response/imageUploadResponse"
import { apiClient } from "./apiClient"

export const getGenreList = async ({
  limit,
  skip,
}: {
  limit?: number
  skip?: number
}): Promise<GenreListResponse> => {
  const response = await apiClient.get<GenreListResponse>("/admin/v1/genre/", {
    params: {
      limit: limit,
      skip: skip,
    },
  })
  return response.data
}

export const createGenre = async (
  genre: GenreCreateFormInput,
): Promise<void> => {
  // TODO
  const res = {
    name: genre.name,
    iconurl: genre.iconUrl,
    imageurl: genre.imageUrl,
    keycolor: genre.keyColor,
  }
  await apiClient.post("/admin/v1/genre/", {
    ...res,
  })
}

export const deleteGenre = async (id: string): Promise<void> => {
  await apiClient.delete(`/admin/v1/genre/${id}`)
}

export const uploadImage = async (
  payload: File,
): Promise<ImageUploadResponse> => {
  const params = new FormData()
  params.append("file", payload)
  const response = await apiClient.post<ImageUploadResponse>(
    "/admin/v1/genre/image",
    params,
  )
  return response.data
}
