import React, { useEffect, useState } from "react"
import { Flex, Text, Box, CircularProgress } from "@chakra-ui/react"
import { DataTable } from "../components/dataTable"
import { Column } from "react-table"
import { Rank } from "../model/entity/ranking"
import { User } from "../model/entity/user"
import { TabType as Tab, RankingTab } from "../components/RankingTab"
import useSWR from "swr"
import {
  fetchMonthlyRankingList,
  fetchAllRankingList,
} from "../repositories/ranking"
import { getGenreList } from "../repositories/genre"
import { SelectField } from "../components/selectField"

type Props = {}

const now = new Date()

function getYear() {
  return now.getFullYear()
}

function getMonth() {
  return now.getMonth() + 1
}

function getLastMonth() {
  const month = getMonth()
  return month - 1 === 0 ? 12 : month - 1
}

export const RankingPage: React.FC<Props> = () => {
  const { data: genre } = useSWR("/getGenreList", getGenreList)
  const [genreId, setGenreId] = useState("")
  const [year, setYear] = useState(getYear())
  const [month, setMonth] = useState(getMonth())
  const [tab, setTab] = useState<Tab>(Tab.ALL)
  const { data: allRankingData } = useSWR(
    ["/fetchAllRankingList", genreId],
    () => fetchAllRankingList({ genreId }),
  )
  const { data: monthRankingData } = useSWR(
    ["/fetchMonthlyRankingList", year, month, genreId],
    () => fetchMonthlyRankingList({ year, month, genreId }),
  )

  const rankingColumns: Column<
    { index: number } & Rank & User
  >[] = React.useMemo(
    () => [
      {
        Header: "順位",
        accessor: "index",
        // TODO: ページネーションを入れる場合は index * ((page-1) * limit)
        // Cell: ({ cell: { value } }) => {
        //   console.log(value)
        //   return null
        // },
      },
      {
        Header: "ユーザーID",
        accessor: "displayId",
      },
      {
        Header: "ユーザー名",
        accessor: "name",
      },
      {
        Header: "スコア",
        accessor: "score",
      },
    ],
    [],
  )

  function handleClickTab(name: Tab) {
    switch (name) {
      case Tab.ALL: {
        setYear(getYear)
        setMonth(getMonth)
        break
      }
      case Tab.LAST_MONTH: {
        const lastMonth = getLastMonth()
        lastMonth === 12 && setYear(getYear() - 1)
        setMonth(lastMonth)
        break
      }
      case Tab.THIS_MONTH: {
        setYear(getYear)
        setMonth(getMonth)
        break
      }
      default: {
        break
      }
    }

    setTab(name)
  }

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          ランキング
        </Text>
      </Flex>

      <Box ml={4} mb={4} width="30%">
        <SelectField
          title="ジャンル"
          name="genre"
          items={genre ?? []}
          onChange={(e) => setGenreId(e.target.value)}
        />
      </Box>

      <Flex direction="row">
        <RankingTab
          title="全期間"
          active={tab === Tab.ALL}
          onClick={() => handleClickTab(Tab.ALL)}
        />
        <RankingTab
          title="先月"
          active={tab === Tab.LAST_MONTH}
          onClick={() => handleClickTab(Tab.LAST_MONTH)}
        />
        <RankingTab
          title="今月"
          active={tab === Tab.THIS_MONTH}
          onClick={() => handleClickTab(Tab.THIS_MONTH)}
        />
      </Flex>

      <Flex direction="column" overflowX="scroll">
        <Box bgColor="gray.200" p={4}>
          {allRankingData == null || monthRankingData == null ? (
            <Flex alignItems="center" justifyContent="center" padding={6}>
              <CircularProgress color="blue.500" isIndeterminate={true} />
            </Flex>
          ) : (
            <Box>
              {tab === Tab.ALL ? (
                <DataTable data={allRankingData} columns={rankingColumns} />
              ) : (
                <DataTable data={monthRankingData} columns={rankingColumns} />
              )}
            </Box>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
