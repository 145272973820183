import React from "react"
import {
  Flex,
  Text,
  Button,
  Box,
  Spacer,
  SimpleGrid,
  FormLabel,
} from "@chakra-ui/react"
import { TextField } from "../components/textField"
import { FormSection } from "../components/formSection"
import { useCreateQuizForm } from "../hooks/useCreateQuizForm"
import { Badge } from "../components/badge"
import { Controller } from "react-hook-form"
import { SelectGenre } from "../components/selectGenre"

type Props = {}

export const QuizCreatePage: React.FC<Props> = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    control,
    isLoading,
    formState,
  } = useCreateQuizForm()

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          クイズ作成
        </Text>
      </Flex>
      <Flex direction="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormSection>
            <SimpleGrid minChildWidth={200} spacingX={10} spacingY={5}>
              <TextField
                ref={register({ required: true })}
                multiline
                required={true}
                name="body"
                title="問題文"
              />
              <TextField
                ref={register({ required: true })}
                multiline
                required={true}
                name="answer"
                title="解答"
              />
              <TextField
                ref={register({ required: true })}
                multiline
                required={true}
                name="answeryomi"
                title="解答(読み方)"
              />
            </SimpleGrid>
            <Spacer marginTop={4} />
            <SimpleGrid minChildWidth={200} spacing={5}>
              <Flex display="flex" flexDir="column">
                <Flex display="flex" flexDir="row">
                  <FormLabel fontWeight="bold">ジャンル</FormLabel>
                  <Badge marginBottom={2}>必須</Badge>
                </Flex>
                <Controller
                  control={control}
                  name="genreid"
                  rules={{
                    required: true,
                  }}
                  render={({ value, onChange }) => {
                    return (
                      <SelectGenre
                        selectedGenreId={value}
                        onChange={onChange}
                      />
                    )
                  }}
                />
              </Flex>
              <TextField
                ref={register({ required: true })}
                required={true}
                name="authorid"
                title="作成者"
              />
            </SimpleGrid>
            <Spacer marginTop={4} />
            <Box>
              <Button
                disabled={!formState.isValid}
                colorScheme="blue"
                type="submit"
                isLoading={isLoading}>
                作成
              </Button>
            </Box>
          </FormSection>
        </form>
      </Flex>
    </Flex>
  )
}
