import { useEffect, useState } from "react"
import { UseFormMethods, useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { createGenre, deleteGenre, getGenreList } from "../repositories/genre"
import { Genre } from "../model/entity/genre"

export type GenreCreateFormInput = {
  name: string
  keyColor: string
  imageUrl: string
  iconUrl: string
}

export const useCreateGenreForm = (): UseFormMethods<GenreCreateFormInput> & {
  onSubmit: (data: GenreCreateFormInput) => Promise<void>
  onDelete: (id: string) => Promise<void>
  isLoading: boolean
  data: Genre[]
} => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const toast = useToast()
  const [data, setData] = useState<Genre[]>([])

  const fetchGenreList = async () => {
    try {
      const response = await getGenreList({})
      setData(response)
    } catch {
      toast({
        position: "top",
        title: "ジャンル一覧の取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const onSubmit = async (data: GenreCreateFormInput) => {
    setIsLoading(true)
    try {
      await createGenre(data)
      await fetchGenreList()
      history.push("/genres")
    } catch (e) {
      toast({
        position: "top",
        title: "ジャンルの作成に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onDelete = async (id: string) => {
    setIsLoading(true)
    try {
      await deleteGenre(id)
      const response = await getGenreList({})
      setData(response)
      history.push("/genres")
    } catch (e) {
      toast({
        position: "top",
        title: "ジャンルの削除に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchGenreList()
  }, [])

  return {
    ...useForm<GenreCreateFormInput>({ mode: "onChange" }),
    onSubmit,
    onDelete,
    isLoading,
    data,
  }
}
