import { NumberInputProps } from "@chakra-ui/core"
import {
  Box,
  Flex,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react"
import React from "react"

type Props = NumberInputProps & {
  initialValue: number
  title: string
  name: string
}

export const NumberField = React.forwardRef<HTMLInputElement, Props>(
  ({ initialValue, title, name, ...rest }, ref) => {
    return (
      <Box>
        <Flex display="flex" alignItems="center">
          <FormLabel fontWeight="bold">{title}</FormLabel>
        </Flex>
        <NumberInput
          // {...rest} // FIXME: 型が合わない
          defaultValue={initialValue}
          min={0}>
          <NumberInputField
            ref={ref as React.Ref<HTMLInputElement>}
            name={name}
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
    )
  },
)
