import React, { useCallback } from "react"
import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Button,
  Box,
  Stack,
  Flex,
  Divider,
} from "@chakra-ui/react"
import {
  FiMenu,
  FiHome,
  FiHeart,
  FiLogOut,
  FiList,
  FiFileText,
  FiCheckSquare,
  FiUsers,
  FiBarChart2,
  FiUserPlus,
} from "react-icons/fi"
import { Link, useHistory } from "react-router-dom"
import { tokenManager } from "../../utils/tokenManager"

type Props = {}

export const AppContainer: React.FC<Props> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const openButtonRef = React.useRef()
  const history = useHistory()

  const handleOnLogoutClick = useCallback(() => {
    tokenManager.clear()
    history.push("/login")
    onClose()
  }, [onClose])

  return (
    <>
      <Flex direction="column">
        <Flex as="header" direction="row" borderBottomWidth="1px" padding={1}>
          <Button
            ref={openButtonRef as any}
            aria-label="Open Drawer"
            padding={1}
            variant="ghost"
            onClick={onOpen}>
            <FiMenu size={20} />
          </Button>
          <Link to="/">
            <Button fontSize="xl" backgroundColor="transparent">
              管理者システム
            </Button>
          </Link>
        </Flex>
        <Stack alignItems="center">
          <Box height="100%" width="100%" maxWidth="1024px">
            {children}
          </Box>
        </Stack>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="left"
        finalFocusRef={openButtonRef as any}
        onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>管理者システム</DrawerHeader>

          <Link to="/quizzes">
            <Button width="100%" variant="ghost" justifyContent="start">
              <FiFileText size={24} />
              <Box paddingLeft={2}>クイズ一覧</Box>
            </Button>
          </Link>

          <Divider />

          <Link to="/users">
            <Button width="100%" variant="ghost" justifyContent="start">
              <FiUsers size={24} />
              <Box paddingLeft={2}>ユーザー一覧</Box>
            </Button>
          </Link>

          <Divider />

          <Link to="/admins">
            <Button width="100%" variant="ghost" justifyContent="start">
              <FiUserPlus size={24} />
              <Box paddingLeft={2}>管理者ユーザー一覧</Box>
            </Button>
          </Link>

          <Divider />

          <Link to="/genres">
            <Button width="100%" variant="ghost" justifyContent="start">
              <FiHeart size={24} />
              <Box paddingLeft={2}>ジャンル一覧</Box>
            </Button>
          </Link>

          <Divider />

          <Link to="/ranking">
            <Button width="100%" variant="ghost" justifyContent="start">
              <FiBarChart2 size={24} />
              <Box paddingLeft={2}>ランキング</Box>
            </Button>
          </Link>

          <Divider />

          <Button
            variant="ghost"
            width="100%"
            justifyContent="start"
            onClick={handleOnLogoutClick}>
            <FiLogOut size={24} />
            <Box paddingLeft={2}>ログアウト</Box>
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  )
}
