import defaultTheme from "@chakra-ui/theme"
import foundations from "./foundations"

const theme = {
  ...defaultTheme,
  ...foundations,
}

export type Theme = typeof theme

export default theme
