import {
  Badge,
  Box,
  Flex,
  FormLabel,
  Input,
  Select,
  SelectProps,
} from "@chakra-ui/react"
import React from "react"
import { Genre } from "../../model/entity/genre"

export type Props = SelectProps & {
  title: string
  required?: boolean
  items: Genre[]
  currentValue?: string
}

export const SelectField = React.forwardRef<SelectProps, Props>(
  ({ title, required, items, currentValue, ...rest }, ref) => {
    return (
      <Box>
        <Flex display="flex" alignItems="center">
          <FormLabel fontWeight="bold">{title}</FormLabel>
          {required && <Badge marginBottom={2}>必須</Badge>}
        </Flex>
        <Select {...rest} ref={ref as React.Ref<HTMLSelectElement>}>
          <option key="all" value="">
            全て
          </option>
          {items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
      </Box>
    )
  },
)
