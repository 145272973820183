import React from "react"
import { Flex, FlexProps } from "@chakra-ui/react"
import { Tab } from "../tab"

export type Props = FlexProps & {
  active: boolean
  title: string
}

export const TabType = {
  LAST_MONTH: "last_month",
  THIS_MONTH: "this_month",
  ALL: "all",
} as const
export type TabType = typeof TabType[keyof typeof TabType]

export const RankingTab: React.FC<Props> = ({ children, ...rest }) => {
  return <Tab {...rest}>{children}</Tab>
}
