import React, { ChangeEvent, FC, useCallback } from "react"
import { Box, Button } from "@chakra-ui/react"

export type Props = {
  label: string
  accept: string
  isLoading: boolean
  isDisabled: boolean
  onChange: (file: File) => void
}

export const FileField: FC<Props> = ({
  label,
  accept,
  isLoading,
  isDisabled,
  onChange,
}) => {
  const onHandleClickButton = useCallback(() => {
    document.getElementById("hiddenInput")?.click()
  }, [])
  return (
    <Box>
      <Button
        colorScheme="blue"
        isLoading={isLoading}
        disabled={isDisabled}
        onClick={() => onHandleClickButton()}>
        {label}
      </Button>
      <input
        hidden
        type="file"
        id="hiddenInput"
        accept={accept}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (!e.target?.files || !e.target?.files[0]) {
            return
          }
          onChange(e.target.files[0])
          // detects change event the same file
          e.target.value = ""
        }}
      />
    </Box>
  )
}
