import { useState } from "react"
import { UseFormMethods, useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/react"
import { updateUser } from "../repositories/user"
import { useHistory } from "react-router-dom"
import { User } from "../model/entity/user"

export type FormInput = {
  id: string
  name: string
  profile: string
  rank: number
  numOfMatch: number
  rate: number
  created: number
}

export const useEditUserForm = (
  initialData?: User,
): UseFormMethods<FormInput> & {
  onSubmit: (data: FormInput) => Promise<void>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const toast = useToast()

  const onSubmit = async (data: FormInput) => {
    setIsLoading(true)
    try {
      await updateUser({ id: data.id, user: data })
      history.push("/users")
    } catch (e) {
      toast({
        position: "top",
        title: "ユーザー編集に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    ...useForm<FormInput>({
      defaultValues: { ...initialData },
      mode: "onChange",
    }),
    onSubmit,
    isLoading,
  }
}
