import {
  RankingListResponse,
  FetchAllRankingList,
} from "../model/response/rankingListResponse"
import { apiClient } from "./apiClient"

export const fetchMonthlyRankingList = async ({
  genreId = "",
  year,
  month,
  limit = 100,
  offset,
}: {
  genreId?: string
  year: number
  month: number
  limit?: number
  offset?: number
}): Promise<FetchAllRankingList> => {
  const response = await apiClient.get<RankingListResponse>(
    "/admin/v1/user/monthly_ranking",
    {
      params: {
        limit,
        offset,
        genreId,
        year,
        month,
      },
    },
  )
  const { ranking: resRanking, users: resUser } = response.data
  const result = resRanking.map((rank, i) => {
    const userIndex = resUser.findIndex((user) => user.id === rank.userId)

    return {
      index: i + 1,
      ...resUser[userIndex],
      ...rank,
    }
  })

  return result
}

export const fetchAllRankingList = async ({
  genreId = "",
  limit = 100,
  offset,
}: {
  genreId?: string
  limit?: number
  offset?: number
}): Promise<FetchAllRankingList> => {
  const response = await apiClient.get<RankingListResponse>(
    "/admin/v1/user/ranking",
    {
      params: {
        limit: limit,
        offset: offset,
        genreId: genreId,
      },
    },
  )
  const { ranking: resRanking, users: resUser } = response.data
  const result = resRanking.map((rank, i) => {
    const userIndex = resUser.findIndex((user) => user.id === rank.userId)

    return {
      index: i + 1,
      ...resUser[userIndex],
      ...rank,
    }
  })

  return result
}
