import React, { useCallback, useEffect } from "react"
import {
  useTable,
  Row,
  UseTableOptions,
  PluginHook,
  Column,
  useExpanded,
  Hooks,
  ColumnInstance,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  FilterValue,
  useRowSelect,
  Cell,
} from "react-table"
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Select,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
} from "@chakra-ui/react"
import {
  FiChevronDown,
  FiChevronUp,
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronsRight,
  FiChevronRight,
  FiSearch,
} from "react-icons/fi"
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa"
import { IndeterminateCheckbox } from "./IndeterminateCheckbox"

type TableSize = "sm" | "md"

type TableStyle = {
  thPaddingX: number
  thPaddingY: number
  tdPaddingX: number[]
  tdPaddingY: number[]
  fontSize: string
  iconButtonSize: number
}

const getStyle = (size: TableSize): TableStyle => {
  switch (size) {
    case "sm":
      return {
        thPaddingX: 2,
        thPaddingY: 0.5,
        tdPaddingY: [1],
        tdPaddingX: [3, 2],
        fontSize: "sm",
        iconButtonSize: 6,
      }
    case "md":
      return {
        thPaddingX: 2,
        thPaddingY: 2,
        tdPaddingY: [2],
        tdPaddingX: [6, 2],
        fontSize: "md",
        iconButtonSize: 10,
      }
  }
}

const GlobalFilter: React.FC<{
  globalFilter: any
  setGlobalFilter: (filterValue: FilterValue) => void
}> = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <Box>
      <InputGroup>
        <InputLeftElement padding={0}>
          <Box as={FiSearch} aria-label="絞り込む" />
        </InputLeftElement>
        <Input
          type="text"
          value={value || ""}
          placeholder="キーワードで絞り込む"
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </InputGroup>
    </Box>
  )
}

export type Props<T extends object> = {
  data: T[]
  columns: Column<T>[]
  size?: TableSize
  onRowClick?: (row: Row<T>) => void
  horizontalScrollable?: boolean
  options?: Omit<UseTableOptions<T>, "data" | "columns"> & {
    [key: string]: any
  }
  plugins?: Array<PluginHook<T>>
  expandableRows?: boolean
  selectableRows?: boolean
  disableAllSelectRows?: boolean
  sortable?: boolean
  searchable?: boolean
  showPagination?: boolean
  tableLayoutFixed?: boolean
  renderExpandableRowsComponent?: (row: Row<T>) => JSX.Element
  onSelectedRows?: (rows: Row<T>[]) => void
  onCurrentDataChange?: (rows: Row<T>[]) => void
}

export const DataTable = <T extends object = {}>({
  data,
  columns,
  size = "md",
  onRowClick,
  horizontalScrollable = true,
  options,
  plugins = [],
  expandableRows,
  selectableRows,
  disableAllSelectRows,
  sortable,
  showPagination,
  searchable,
  tableLayoutFixed = false,
  renderExpandableRowsComponent,
  onSelectedRows,
  onCurrentDataChange,
}: Props<T>): JSX.Element => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      ...options,
    },
    ...plugins,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks: Hooks<T>) => {
      hooks.visibleColumns.push((columns) => {
        const result: Array<Column<T>> = [...columns]
        if (selectableRows) {
          result.unshift({
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Box>
                {!disableAllSelectRows && (
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                )}
              </Box>
            ),
            Cell: ({ row }: Cell<T>) => (
              <Box>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </Box>
            ),
          })
        }
        if (expandableRows) {
          result.push({
            id: "expander",
            width: "40px",
            Cell: ({ row }) => (
              <Box
                {...(row as any).getToggleRowExpandedProps({
                  onClick: () => {
                    row.toggleRowExpanded()
                  },
                })}>
                {row.isExpanded ? (
                  <>
                    <IconButton
                      variant="ghost"
                      aria-label="expand button"
                      display={["none", "flex"]}
                      justifyContent="center"
                      icon={<Box as={FiChevronDown} size="24px" />}
                    />
                    <IconButton
                      variant="ghost"
                      aria-label="expand button"
                      display={["flex", "none"]}
                      justifyContent="center"
                      icon={<Box as={FiChevronUp} size="24px" />}
                    />
                  </>
                ) : (
                  <>
                    <IconButton
                      variant="ghost"
                      aria-label="expand button"
                      display={["none", "flex"]}
                      justifyContent="center"
                      icon={<Box as={FiChevronRight} size="24px" />}
                    />
                    <IconButton
                      variant="ghost"
                      aria-label="expand button"
                      display={["flex", "none"]}
                      justifyContent="center"
                      icon={<Box as={FiChevronDown} size="24px" />}
                    />
                  </>
                )}
              </Box>
            ),
          } as ColumnInstance<T>)
        }
        return result
      })
    },
  )

  const onHandleRowClick = useCallback(
    (row: Row<T>) => () => {
      onRowClick?.(row)
    },
    [onRowClick],
  )

  useEffect(() => {
    onSelectedRows?.(selectedFlatRows)
  }, [selectedFlatRows, onSelectedRows])

  useEffect(() => {
    onCurrentDataChange?.(showPagination ? page : rows)
  }, [showPagination, page, rows, onCurrentDataChange])

  const isRowClickable = !!onRowClick

  const style = getStyle(size)

  return (
    <Box>
      {searchable && (
        <Box paddingX={[4, 0]} marginBottom={4}>
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Box>
      )}
      {/* {selectableRows && (
        <Box paddingX={[4, 0]} marginBottom={4}>
          <Button size="sm" onClick={() => toggleAllRowsSelected(false)}>
            選択解除
          </Button>
        </Box>
      )} */}
      <Box
        backgroundColor={["gray.100", "background"]}
        overflowX={horizontalScrollable ? "scroll" : "visible"}
        whiteSpace="nowrap"
        paddingBottom={horizontalScrollable ? [0, 4] : 0}>
        <Box
          as="table"
          width="full"
          paddingBottom={horizontalScrollable ? 4 : 0}
          style={{
            tableLayout: tableLayoutFixed ? "fixed" : "auto",
          }}
          {...getTableProps()}>
          <Box as="thead" display={["none", "table-header-group"]}>
            {headerGroups.map((headerGroup) => (
              <Box
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
                as="tr"
                display={["block", "table-row"]}
                backgroundColor="background">
                {headerGroup.headers.map((column) => {
                  return (
                    <Box
                      as="th"
                      {...column.getHeaderProps(
                        sortable && (column as any).getSortByToggleProps(),
                      )}
                      key={column.id}
                      textAlign={
                        typeof column.Header === "string" ? "left" : "center"
                      }
                      paddingY={style.thPaddingY}
                      paddingX={style.thPaddingX}
                      width={column.width}
                      fontSize={style.fontSize}>
                      <Box
                        display="inline-flex"
                        color={
                          sortable &&
                          column.id !== "selection" &&
                          column.isSorted
                            ? "primary"
                            : "primaryText"
                        }>
                        {column.render("Header")}
                      </Box>
                      {sortable &&
                        column.id !== "selection" &&
                        (column.isSorted ? (
                          <span>
                            {column.isSortedDesc ? (
                              <IconButton
                                width={style.iconButtonSize}
                                height={style.iconButtonSize}
                                aria-label=""
                                variant="ghost"
                                color="blue.500"
                                icon={<Box as={FaSortDown} />}
                              />
                            ) : (
                              <IconButton
                                width={style.iconButtonSize}
                                height={style.iconButtonSize}
                                aria-label=""
                                variant="ghost"
                                color="blue.500"
                                icon={<Box as={FaSortUp} />}
                              />
                            )}
                          </span>
                        ) : (
                          <IconButton
                            width={style.iconButtonSize}
                            height={style.iconButtonSize}
                            aria-label=""
                            variant="ghost"
                            color="gray.300"
                            icon={<Box as={FaSort} />}
                          />
                        ))}
                    </Box>
                  )
                })}
              </Box>
            ))}
          </Box>
          <Box as="tbody" {...getTableBodyProps()}>
            {(showPagination ? page.length : rows.length) > 0 ? (
              (showPagination ? page : rows).map((row) => {
                prepareRow(row)
                return (
                  <React.Fragment
                    key={(row.original as any).id ?? row.getRowProps().key}>
                    <Box
                      as="tr"
                      display={["block", "table-row"]}
                      {...row.getRowProps()}
                      paddingY={[4, 0]}
                      borderTop="1px"
                      borderColor="gray.200"
                      backgroundColor="background"
                      cursor={isRowClickable ? "pointer" : "default"}
                      marginBottom={[
                        expandableRows && row.isExpanded ? 0 : 4,
                        0,
                      ]}
                      _last={{ marginBottom: 0 }}
                      _hover={{
                        backgroundColor: "gray.50",
                      }}
                      onClick={onHandleRowClick(row)}>
                      {row.cells.map((cell) => {
                        return (
                          <Box
                            as="td"
                            paddingY={style.tdPaddingY}
                            paddingX={style.tdPaddingX}
                            {...cell.getCellProps()}
                            key={cell.getCellProps().key}
                            display={["block", "table-cell"]}>
                            <Flex align={["flex-start", "center"]}>
                              <Box
                                display={["block", "none"]}
                                fontWeight="bold"
                                marginRight="auto"
                                paddingRight={4}>
                                {cell.column.render("Header")}
                              </Box>
                              {typeof cell.column.Header === "string" ? (
                                <Text as="div" fontSize={style.fontSize}>
                                  {cell.render("Cell")}
                                </Text>
                              ) : (
                                <Flex justifyContent="center" width="full">
                                  {cell.render("Cell")}
                                </Flex>
                              )}
                            </Flex>
                          </Box>
                        )
                      })}
                    </Box>
                    {expandableRows && row.isExpanded && (
                      <Box
                        as="tr"
                        backgroundColor="background"
                        marginBottom={[4, 0]}>
                        <Box as="td" colSpan={row.cells.length}>
                          {renderExpandableRowsComponent?.(row)}
                        </Box>
                      </Box>
                    )}
                  </React.Fragment>
                )
              })
            ) : (
              <tr>
                <Box
                  as="td"
                  paddingY={10}
                  colSpan={expandableRows ? columns.length + 2 : columns.length}
                  backgroundColor="background">
                  <Text textAlign="center">データがありません</Text>
                </Box>
              </tr>
            )}
          </Box>
        </Box>
      </Box>
      {showPagination && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingTop={4}
          paddingBottom={2}
          paddingX={[4, 1]}
          flexWrap="wrap">
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton
              width={10}
              height={10}
              aria-label=""
              icon={<Box as={FiChevronsLeft} />}
              isDisabled={!canPreviousPage}
              onClick={() => gotoPage(0)}
            />
            <IconButton
              width={10}
              height={10}
              aria-label=""
              icon={<Box as={FiChevronLeft} />}
              isDisabled={!canPreviousPage}
              onClick={() => previousPage()}
            />
            <IconButton
              width={10}
              height={10}
              aria-label=""
              icon={<Box as={FiChevronRight} />}
              isDisabled={!canNextPage}
              onClick={() => nextPage()}
            />
            <IconButton
              width={10}
              height={10}
              aria-label=""
              icon={<Box as={FiChevronsRight} />}
              isDisabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
            />
            <Text>
              {pageIndex + 1} of {pageOptions.length}
            </Text>
          </Stack>
          <Flex alignItems="center" marginTop={[4, 0]}>
            <Text marginRight={2} whiteSpace="nowrap">
              表示件数
            </Text>
            <Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}>
              {[10, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}件
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
      )}
    </Box>
  )
}
