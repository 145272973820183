import axios, { AxiosInstance } from "axios"
import applyConverters from "axios-case-converter"
import { tokenManager } from "../utils/tokenManager"
import { baseUrl } from "../env"

export type APIClient = AxiosInstance

export function createAPIClient(): APIClient {
  const instance = axios.create({
    baseURL: baseUrl(),
    timeout: 3000,
  })

  instance.interceptors.request.use((request) => {
    request.headers["X-User-Token"] = tokenManager.get()
    return request
  })

  // FIXME: AxiosInstanceの型が合わないので一旦any
  return applyConverters(instance as any) as any
}

export const apiClient = createAPIClient()
