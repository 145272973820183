import { useCallback, useEffect, useState } from "react"
import { useDisclosure, useToast, UseDisclosureReturn } from "@chakra-ui/react"
import { createQuiz, createQuizzes } from "../repositories/quiz"
import { QuizCreateFormInput } from "./useCreateQuizForm"

export const useImportQuizCSV = (): UseDisclosureReturn & {
  onUploadCsvFile: (file: File) => Promise<void>
  isProcessing: boolean
  csvPreviewData: QuizCreateFormInput[]
  handleImport: () => void
} => {
  const [csvPreviewData, setCsvPreviewData] = useState<QuizCreateFormInput[]>(
    [],
  )
  const [isProcessing, setIsProcessing] = useState(false)
  const toast = useToast()
  const { onOpen, ...rest } = useDisclosure()

  const handleImport = async (): Promise<void> => {
    setIsProcessing(true)
    await createQuizzes(csvPreviewData)

    try {
      toast({
        position: "top",
        title: "インポートが完了しました",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
    } catch (e) {
      toast({
        position: "top",
        title: "インポートに失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsProcessing(false)
    }
  }

  const readCsvFile = (file: File): Promise<Array<string[]>> => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      const data: Array<string[]> = []
      reader.onload = () => {
        if (reader.result) {
          const result = reader.result as string
          const lines = result.split(/\n/)
          for (let i = 0; i < lines.length; i++) {
            data[i] = lines[i].replace(/\r?\n|\r/g, "").split(",")
          }
          resolve(data)
        }
      }
      reader.readAsText(file)
    })
  }

  const onUploadCsvFile = async (file: File) => {
    setIsProcessing(true)
    try {
      const rows = await readCsvFile(file)
      const data = []

      for (let i = 0; i < rows.length; i++) {
        if (
          rows[i][0] != null &&
          rows[i][1] != null &&
          rows[i][2] != null &&
          rows[i][3] != null &&
          rows[i][4] != null &&
          i !== 0
        ) {
          data.push({
            authorid: rows[i][0],
            body: rows[i][1],
            answeryomi: rows[i][2],
            answer: rows[i][3],
            genreid: rows[i][4],
          })
        }
      }
      setCsvPreviewData(data)

      onOpen()

      toast({
        position: "top",
        title: "CSVファイルの読み込みが完了しました",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
    } catch (e) {
      toast({
        position: "top",
        title: "CSVファイルの読み込みに失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsProcessing(false)
    }
  }

  return {
    ...rest,
    isProcessing,
    csvPreviewData,
    onOpen,
    onUploadCsvFile,
    handleImport,
  }
}
