import React from "react"
import { FiImage } from "react-icons/fi"
import { Box, Image, Flex } from "@chakra-ui/react"
import { useImagePicker } from "../../hooks/useImagePicker"

export type Props = {
  width: string
  height: string
  aspect: number
  value: string
  onChange: (imageUrl: string) => void
}

export const SingleImagePicker: React.FC<Props> = ({
  width = "40px",
  height,
  aspect,
  value = "",
  onChange,
}) => {
  const { getRootProps, getInputProps, isUploading } = useImagePicker({
    onUpload: (image) => {
      onChange(image)
    },
  })

  return (
    <Box
      display="flex"
      borderWidth={1}
      width={width}
      height={height ?? width}
      maxWidth="100%"
      backgroundColor="gray.100"
      borderRadius="md"
      cursor="pointer"
      overflow="hidden">
      {value !== "" ? (
        <Image src={value} objectFit="cover" />
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          width="full"
          height="full"
          {...getRootProps()}>
          <Box
            as={FiImage}
            width="30%"
            height="30%"
            cursor="pointer"
            aria-label="画像選択"
          />
          <input {...getInputProps()} />
        </Flex>
      )}
    </Box>
  )
}
