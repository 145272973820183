const JWT_STORAGE_KEY = "_pUt"

class TokenManager {
  private token: string | null
  constructor() {
    this.token = null
  }
  public set(token: string) {
    this.token = token
    localStorage.setItem(JWT_STORAGE_KEY, token)
  }
  public get() {
    let token = this.token
    if (process.title === "browser" && this.token == null) {
      token = localStorage.getItem(JWT_STORAGE_KEY)
    }
    return token
  }
  public clear() {
    this.token = null
    localStorage.removeItem(JWT_STORAGE_KEY)
  }
}

export const tokenManager = new TokenManager()
