import React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { tokenManager } from "./utils/tokenManager"

type Props = RouteProps & {}

export const PrivateRoute: React.FC<Props> = ({ ...rest }) => {
  if (!tokenManager.get()) {
    return <Redirect to="/login" />
  }

  return <Route {...rest} />
}
