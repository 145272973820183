import { useEffect, useState } from "react"
import { UseFormMethods, useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/react"
import { getAdminDetail, updateAdmin, deleteAdmin, getAdminList } from "../repositories/admin"
import { useHistory } from "react-router-dom"

export type FormInput = {
  name: string
  password: string
  email: string
}

export const useEditAdminForm = (
  adminId: string,
): UseFormMethods<FormInput> & {
  onSubmit: (data: FormInput) => Promise<void>
  onDelete: (adminId: string) => Promise<void>
  fetchSucceeded: boolean
  isLoading: boolean
  isLastAdmin: boolean
} => {
  const [fetchSucceeded, setFetchSucceeded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLastAdmin, setIsLastAdmin] = useState(false)
  const history = useHistory()
  const toast = useToast()
  const { reset, ...rest } = useForm<FormInput>()

  const fetchAdminDetail = async () => {
    try {
      const admin = await getAdminDetail(adminId)
      reset({
        name: admin.name,
        email: admin.email,
      })
      setFetchSucceeded(true)
    } catch {
      toast({
        position: "top",
        title: "データの取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const fetchAdminCount = async () => {
    try {
      const adminList = await getAdminList()
      const adminCount = adminList.length
      adminCount === 1 ? setIsLastAdmin(true) : setIsLastAdmin(false)
    } catch {
      toast({
        position: "top",
        title: "データの取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (adminId) {
      fetchAdminDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminId])

  useEffect(() => {
    fetchAdminCount()
  }, [])

  const onSubmit = async (data: FormInput) => {
    setIsLoading(true)
    try {
      await updateAdmin({ id: adminId, admin: data })
      history.push("/admins")
    } catch (e) {
      toast({
        position: "top",
        title: "管理ユーザー編集に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onDelete = async (adminId: string) => {
    setIsLoading(true)
    try {
      deleteAdmin(adminId)
      toast({
        position: "top",
        title: "削除が完了しました",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
      history.push("/admins")
    } catch {
      toast({
        position: "top",
        title: "削除に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    ...rest,
    reset,
    onSubmit,
    onDelete,
    fetchSucceeded,
    isLoading,
    isLastAdmin,
  }
}
