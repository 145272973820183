import React, { useCallback, useEffect } from "react"
import {
  Flex,
  Text,
  Button,
  Box,
  useToast,
  CircularProgress,
} from "@chakra-ui/react"
import { Column, Row } from "react-table"
import useSWR from "swr"
import { useHistory } from "react-router-dom"
import { DataTable } from "../components/dataTable"
import { getAdminList } from "../repositories/admin"
import { Admin } from "../model/entity/admin"

type Props = {}

export const AdminListPage: React.FC<Props> = () => {
  const history = useHistory()
  const toast = useToast()
  const { data, error } = useSWR("/getAdminList", getAdminList)

  // // TODO
  // const error = null
  // const mockData: Admin[] = [
  //   { id: "0", email: "a@gmail.com", name: "admin", created: 0, updated: 0 },
  // ]
  // const data = mockData

  const columns: Column<Admin>[] = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "ユーザー名",
        accessor: "name",
      },
      {
        Header: "メールアドレス",
        accessor: "email",
      },
    ],
    [],
  )

  const onHandleRowClick = useCallback(
    (row: Row<Admin>) => {
      history.push(`/admins/${row.original.id}`)
    },
    [history],
  )

  const handleClick = useCallback(() => {
    history.push("/admins/new")
  }, [history])

  useEffect(() => {
    if (error) {
      toast({
        position: "top",
        title: "データの取得に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }, [error, toast])

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          管理ユーザー一覧
        </Text>
        <Button colorScheme="blue" onClick={handleClick}>
          ユーザーを作成する
        </Button>
      </Flex>
      <Flex direction="column" overflowX="scroll">
        <Box backgroundColor={["gray.100", "background"]} padding={4}>
          {data == null ? (
            <Flex alignItems="center" justifyContent="center" padding={6}>
              <CircularProgress color="blue.500" isIndeterminate={true} />
            </Flex>
          ) : (
            <DataTable
              data={data}
              columns={columns}
              searchable={true}
              onRowClick={onHandleRowClick}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
