export type ColorHues = {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

export type Colors = typeof colors

const colors = {
  transparent: "transparent",
  current: "currentColor",
  black: "#000000", // 出来るだけ使わない
  white: "#FFFFFF", // 出来るだけ使わない

  primaryText: "#303030",
  subText: "#7F7F7F",
  primary: "#1AA2BC",
  success: "#14E069",
  warning: "#FF8707",
  danger: "#FF4635",
  background: "#FFFFFF",
  divider: "#CCCCCC",

  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
  },

  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)",
  },

  // TODO
  gray: {
    50: "#F7FAFC",
    100: "#EDF2F7",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#718096",
    600: "#4A5568",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923",
  },

  red: {
    50: "#FFFFFF",
    100: "#FFE7D6",
    200: "#FFC8AE",
    300: "#FFA385",
    400: "#FF8067",
    500: "#FF4635",
    600: "#DB2726",
    700: "#B71A27",
    800: "#931026",
    900: "#7A0A25",
  },

  orange: {
    50: "#FFFFFF",
    100: "#FFF0CD",
    200: "#FFDD9B",
    300: "#FFC56A",
    400: "#FFAE45",
    500: "#FF8707",
    600: "#DB6905",
    700: "#B74E03",
    800: "#933802",
    900: "#7A2801",
  },

  green: {
    50: "#FFFFFF",
    100: "#CFFDD0",
    200: "#A0FBAA",
    300: "#6FF58C",
    400: "#4AEC7E",
    500: "#14E069",
    600: "#0EC06A",
    700: "#0AA167",
    800: "#06815E",
    900: "#036B58",
  },

  blue: {
    50: "#FFFFFF",
    100: "#D0FBF5",
    200: "#A2F8F2",
    300: "#71EAEA",
    400: "#4BCBD6",
    500: "#1AA2BC",
    600: "#137FA1",
    700: "#0D6087",
    800: "#08456D",
    900: "#04325A",
  },
}

export default colors
