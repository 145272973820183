import React, { useEffect } from "react"
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  Button,
  Box,
  Spacer,
  SimpleGrid,
  CircularProgress,
  useToast,
  FormLabel,
} from "@chakra-ui/react"
import { Controller } from "react-hook-form"
import { TextField } from "../components/textField"
import { FormSection } from "../components/formSection"
import { useParams } from "react-router-dom"
import { useEditQuizForm } from "../hooks/useEditQuizForm"
import { SelectGenre } from "../components/selectGenre"
import { Badge } from "../components/badge"

type Props = {}

export const QuizEditPage: React.FC<Props> = () => {
  const { id: quizId } = useParams<{ id: string }>()
  const {
    register,
    handleSubmit,
    onSubmit,
    onDelete,
    control,
    errors,
    isLoading,
    formState,
    fetchSucceeded,
  } = useEditQuizForm(quizId)
  const { isSubmitting } = formState

  return (
    <Flex direction="column" paddingBottom={20} maxWidth="5xl" marginX="auto">
      <Flex
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={4}
        paddingX={4}>
        <Text color="primaryText" fontSize="lg" fontWeight="bold">
          クイズ詳細
        </Text>
        <Button
          type="button"
          colorScheme="red"
          isLoading={isLoading}
          onClick={() => {
            if (window.confirm("クイズを削除")) {
              onDelete(quizId)
            }
          }}>
          クイズを削除する
        </Button>
      </Flex>
      <Flex direction="column" overflowX="scroll">
        <Box backgroundColor={["gray.100", "background"]} p={4}>
          {!fetchSucceeded ? (
            <Flex alignItems="center" justifyContent="center" padding={6}>
              <CircularProgress color="blue.500" isIndeterminate={true} />
            </Flex>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormSection>
                <SimpleGrid minChildWidth={200} spacingX={10} spacingY={5}>
                  <FormControl isInvalid={errors.body != null}>
                    <TextField
                      ref={register({ required: true })}
                      multiline
                      required
                      name="body"
                      title="問題文"
                    />
                    <FormErrorMessage>
                      {errors.body && "問題文が入力されていません"}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.answer != null}>
                    <TextField
                      ref={register({ required: true })}
                      multiline
                      required={true}
                      name="answer"
                      title="解答"
                    />
                    <FormErrorMessage>
                      {errors.answer && "解答が入力されていません"}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.answeryomi != null}>
                    <TextField
                      ref={register({ required: true })}
                      multiline
                      required={true}
                      name="answeryomi"
                      title="解答(読み方)"
                    />
                    <FormErrorMessage>
                      {errors.answeryomi && "解答(読み方)が入力されていません"}
                    </FormErrorMessage>
                  </FormControl>
                </SimpleGrid>

                <Spacer marginTop={4} />
                <SimpleGrid minChildWidth={200} spacing={5}>
                  <Flex display="flex" flexDir="column">
                    <Flex display="flex" flexDir="row">
                      <FormLabel fontWeight="bold">ジャンル</FormLabel>
                      <Badge marginBottom={2}>必須</Badge>
                    </Flex>
                    <FormControl isInvalid={errors.genreid != null}>
                      <Controller
                        control={control}
                        name="genreid"
                        rules={{
                          required: true,
                        }}
                        render={({ value, onChange }) => (
                          <SelectGenre
                            selectedGenreId={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.genreid && "ジャンルが選択されていません"}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <FormControl isInvalid={errors.authorid != null}>
                    <TextField
                      ref={register({ required: true })}
                      required={true}
                      title="作成者"
                      name="authorid"
                    />
                    <FormErrorMessage>
                      {errors.authorid && "作成者が入力されていません"}
                    </FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
                <Spacer marginTop={4} />
                <Box>
                  <Button
                    disabled={isSubmitting}
                    colorScheme="blue"
                    type="submit"
                    isLoading={isLoading}>
                    編集
                  </Button>
                </Box>
              </FormSection>
            </form>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
