import React from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureProps,
} from "@chakra-ui/react"

type ModalType = Pick<UseDisclosureProps, "isOpen" | "onClose">

type Props = Required<ModalType> & {
  button: {
    onClick: () => void
    name: string
  }
}

export const MyModal: React.FC<Props> = ({
  isOpen,
  onClose,
  button,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} blockScrollOnMount={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent pt={10}>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            キャンセル
          </Button>
          <Button colorScheme="red" onClick={button.onClick}>
            {button.name}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
