import React from "react"
import {
  FormErrorMessage,
  FormControl,
  Input,
  Button,
  Flex,
  Image,
  Box,
  Heading,
  Spacer,
} from "@chakra-ui/react"
import { useLoginForm } from "../hooks/useLoginForm"

export const LoginPage: React.FC = () => {
  const { register, errors, handleSubmit, onSubmit, isLoading } = useLoginForm()

  return (
    <Flex height="100vh">
      <Flex
        width={["0%", "40%", "50%"]}
        backgroundColor="gray.100"
        justifyContent="center">
        <Image src="/images/login.png" maxWidth="md" objectFit="contain" />
      </Flex>
      <Flex
        display="flex"
        flexDirection="column"
        width={["100%", "60%", "50%"]}
        paddingX={[8, 8, 16]}
        paddingY={8}
        backgroundColor="background">
        <Box
          as="form"
          maxWidth="md"
          paddingY={10}
          onSubmit={handleSubmit(onSubmit)}>
          <Spacer marginTop={10} />
          {/* <Image src="/images/logo_black.svg" /> */}
          <Spacer marginTop={8} />
          <Heading>Admin</Heading>
          <Spacer marginTop={10} />
          <FormControl isInvalid={errors.email != null}>
            <Input
              ref={register({
                required: true,
              })}
              name="email"
              placeholder="メールアドレス"
            />
            <FormErrorMessage>
              {"メールアドレスが入力されていません"}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.password != null}>
            <Spacer marginTop={4} />

            <Input
              ref={register({
                required: true,
              })}
              name="password"
              type="password"
              placeholder="パスワード"
            />
            <FormErrorMessage>
              {errors.password && "パスワードが入力されていません"}
            </FormErrorMessage>
          </FormControl>

          <Spacer marginTop={10} />

          <Button
            colorScheme="blue"
            width="full"
            size="lg"
            isLoading={isLoading}
            type="submit">
            ログイン
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}
