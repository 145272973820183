import { useState } from "react"
import { UseFormMethods, useForm } from "react-hook-form"
import { useToast } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { createQuiz } from "../repositories/quiz"

export type QuizCreateFormInput = {
  authorid: string
  body: string
  answer: string
  answeryomi: string
  genreid: string
}

export const useCreateQuizForm = (): UseFormMethods<QuizCreateFormInput> & {
  onSubmit: (data: QuizCreateFormInput) => Promise<void>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const toast = useToast()

  const onSubmit = async (data: QuizCreateFormInput) => {
    setIsLoading(true)
    try {
      await createQuiz(data)
      history.push("/quizzes")
    } catch (e) {
      toast({
        position: "top",
        title: "クイズの作成に失敗しました",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    ...useForm<QuizCreateFormInput>({ mode: "onChange" }),
    onSubmit,
    isLoading,
  }
}
