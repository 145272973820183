import { useState } from "react"
import { useForm, UseFormMethods } from "react-hook-form"
import { login } from "../repositories/auth"
import { useHistory } from "react-router"
import { tokenManager } from "../utils/tokenManager"

type FormInput = {
  email: string
  password: string
}

export const useLoginForm = (): UseFormMethods<FormInput> & {
  onSubmit: (data: FormInput) => Promise<void>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const onSubmit = async (data: FormInput) => {
    try {
      setIsLoading(true)
      // TODO
      const token = await login(data.email, data.password)
      tokenManager.set(token)
      history.push("/")
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    ...useForm<FormInput>(),
    onSubmit,
    isLoading,
  }
}
